<template>
  <div class="page">
    <section>
      <!-- 面包屑 -->
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>消息通知</el-breadcrumb-item>
      </el-breadcrumb>

      <section class="tabs-box">
        <!-- tab栏 -->
        <el-tabs v-model="activeName" :before-leave="handleClick">
          <el-tab-pane label="全部"> </el-tab-pane>
          <el-tab-pane label="系统通知"> </el-tab-pane>
          <el-tab-pane label="评论"> </el-tab-pane>
          <el-tab-pane label="粉丝"> </el-tab-pane>
          <el-tab-pane label="点赞"> </el-tab-pane>
        </el-tabs>

        <!-- 内容 -->
        <div class="notif-box" v-loading="loading">
          <!-- 暂无数据 -->
          <div v-if="!loading && !msgList.length" class="null-box">
            <img
              :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
              alt=""
              srcset=""
            />
            <div class="title">暂无数据</div>
          </div>

          <!-- 消息通知列表 -->
          <template v-for="item in msgList">
            <!-- 评论 -->
            <div
              v-if="item.type == 2 && item.data.doctor.user_id"
              class="notif-zi"
              :key="item.id"
            >
              <img
                @click="
                  xin.goUrl('/found-personal?id=' + item.data.doctor.user_id)
                "
                :src="
                  item.data.doctor.photo
                    ? item.data.doctor.photo
                    : require('@image/default.png')
                "
                alt=""
              />
              <div class="notif-right">
                <div class="notif-top">
                  <span
                    @click="
                      xin.goUrl(
                        '/found-personal?id=' + item.data.doctor.user_id
                      )
                    "
                    class="name"
                    >{{ item.data.doctor.name }}</span
                  >
                  <span class="huif">回复了</span>
                  <span class="time">{{
                    item.create_time | timeFormat("yyyy-mm-dd-hh:mm")
                  }}</span>
                </div>
                <div class="notif-bottom">
                  <div
                    v-html="item.data.content"
                    v-if="item.data.content"
                    class="content sheng"
                  ></div>
                  <div @click="jump(item)" class="content-child">
                    <img
                      v-if="
                        item.data.data &&
                        item.data.data.images &&
                        item.data.data.images[0]
                      "
                      :src="item.data.data.images[0]"
                      alt=""
                    />
                    <div
                      v-if="
                        item.data.data &&
                        !item.data.data.title &&
                        item.data.data.type == 2
                      "
                      class="sheng"
                      v-html="item.data.data.content"
                    ></div>
                    <div
                      v-if="
                        item.data.data &&
                        item.data.data.title &&
                        item.data.data.type == 2
                      "
                      class="sheng"
                      v-html="item.data.data.title"
                    ></div>
                    <div
                      v-if="
                        item.data.data &&
                        item.data.data.title &&
                        item.data.data.type != 2
                      "
                      class="sheng"
                      v-html="xin.formatRichText(item.data.data.title)"
                    ></div>
                    <div
                      v-if="
                        item.data.data &&
                        !item.data.data.title &&
                        item.data.data.content &&
                        item.data.data.type != 2
                      "
                      class="sheng"
                      v-html="xin.formatRichText(item.data.data.content)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 粉丝 -->
            <div
              v-else-if="item.type == 3 && item.data"
              class="notif-zi"
              :key="item.id"
            >
              <img
                @click="xin.goUrl('/found-personal?id=' + item.data.user_id)"
                :src="
                  item.data.photo
                    ? item.data.photo
                    : require('@image/default.png')
                "
                alt=""
              />
              <div class="notif-right">
                <div class="notif-top">
                  <span
                    class="name"
                    @click="
                      xin.goUrl('/found-personal?id=' + item.data.user_id)
                    "
                    >{{ item.data.name }}</span
                  >
                  <span class="huif">关注了你</span>
                  <span class="time">{{
                    item.create_time | timeFormat("yyyy-mm-dd-hh:mm")
                  }}</span>
                </div>
              </div>
            </div>

            <!-- 点赞 -->
            <div
              v-else-if="item.type == 4 && item.data"
              class="notif-zi"
              :key="item.id"
            >
              <img
                @click="
                  xin.goUrl('/found-personal?id=' + item.data.doctor.user_id)
                "
                :src="
                  item.data.doctor.photo
                    ? item.data.doctor.photo
                    : require('@image/default.png')
                "
                alt=""
              />
              <div class="notif-right">
                <div class="notif-top">
                  <span
                    class="name"
                    @click="
                      xin.goUrl(
                        '/found-personal?id=' + item.data.doctor.user_id
                      )
                    "
                    >{{ item.data.doctor.name }}</span
                  >
                  <span class="huif">点赞了</span>
                  <span class="time">{{
                    item.create_time | timeFormat("yyyy-mm-dd-hh:mm")
                  }}</span>
                </div>
                <div @click="jump(item)" class="notif-bottom">
                  <!-- <div v-if="item.data.content" class="content sheng">
										{{item.data.data.content}}
									</div> -->
                  <div v-if="item.data.data" class="content-child">
                    <img
                      v-if="
                        item.data.data.images && item.data.data.images.length
                      "
                      :src="item.data.data.images[0]"
                      alt=""
                    />
                    <div
                      v-if="
                        item.data.data.type == 2 &&
                        !item.data.data.title &&
                        item.data.data.content
                      "
                      v-html="item.data.data.content"
                      class="sheng"
                    ></div>
                    <div
                      v-if="item.data.data.type == 2 && item.data.data.title"
                      v-html="item.data.data.title"
                      class="sheng"
                    ></div>
                    <div
                      v-html="item.data.data.content"
                      v-else
                      class="sheng"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 系统通知 -->
            <div v-else class="notif-zi" style="display: block" :key="item.id">
              <div
                v-if="item.content == `您有一条新的回答` && item.data"
                class="notif-zi"
                :key="item.id"
              >
                <img
                  @click="
                    xin.goUrl('/found-personal?id=' + item.data.doctor.user_id)
                  "
                  :src="
                    item.data.doctor.photo
                      ? item.data.doctor.photo
                      : require('@image/default.png')
                  "
                  alt=""
                />
                <div class="notif-right">
                  <div class="notif-top">
                    <span
                      @click="
                        xin.goUrl(
                          '/found-personal?id=' + item.data.doctor.user_id
                        )
                      "
                      class="name"
                      >{{ item.data.doctor.name }}</span
                    >
                    <span class="huif">回答了</span>
                    <span class="time">{{
                      item.create_time | timeFormat("yyyy-mm-dd-hh:mm")
                    }}</span>
                  </div>
                  <div class="notif-bottom">
                    <div
                      v-html="item.data.content"
                      v-if="item.data.content"
                      class="content sheng"
                    ></div>
                    <div @click="jump(item)" class="content-child">
                      <img
                        v-if="
                          item.data.data &&
                          item.data.data.images &&
                          item.data.data.images[0]
                        "
                        :src="item.data.data.images[0]"
                        alt=""
                      />
                      <div
                        v-if="item.data.data && item.data.data.type == 2"
                        class="sheng"
                        v-html="item.data.data.content"
                      ></div>

                      <div
                        v-if="
                          item.data.data &&
                          item.data.data.title &&
                          item.data.data.type != 2
                        "
                        class="sheng"
                        v-html="xin.formatRichText(item.data.data.title)"
                      ></div>
                      <div
                        v-if="
                          item.data.data &&
                          !item.data.data.title &&
                          item.data.data.content &&
                          item.data.data.type != 2
                        "
                        class="sheng"
                        v-html="xin.formatRichText(item.data.data.content)"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else style="display: flex; align-items: center">
                <img
                  style="width: 50px; height: 50px"
                  :src="
                    $http.baseURL + '/static/images/web/072fc2018e6867c1.png'
                  "
                  alt=""
                />
                <div class="notif-right">
                  <div class="notif-top">
                    <span class="name">系统通知</span>
                    <span class="time">{{
                      item.create_time | timeFormat("yyyy-mm-dd-hh:mm")
                    }}</span>
                  </div>
                  <div class="notif-bottom">
                    <div class="content" v-html="item.content"></div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div v-if="msgList.length" class="no-more">没有更多数据了</div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      activeName: 0,
      loading: false,
      msgList: [],
      total: 0,
      per_page: 10,
      page: 1,
    };
  },

  filters: {
    typeName(val) {
      switch (val) {
        case 2:
          val = "回复了";
          break;
        case 3:
          val = "关注了你";
          break;
        case 4:
          val = "点赞了";
          break;
      }
      return val;
    },
  },

  watch: {
    $route(val) {
      let index = val.query.index;
      if (index) {
        this.activeName = index;
      }
    },
    async activeName(val) {
      const newUrl = `#/notification?index=${val}`;
      window.history.replaceState("", "", newUrl);

      // 无跳转重写urlwindow.history.replaceState
      // window.history.pushState(data, title, targetURL);
      // @状态对象：传给目标路由的信息,可为空
      // @页面标题：目前所有浏览器都不支持,填空字符串即可
      // @可选url：目标url，不会检查url是否存在，且不能跨域。如不传该项,即给当前url添加data
      // 二、window.history.replaceState(data, title, targetURL);
      // @类似于pushState，但是会直接替换掉当前url，而不会在history中留下记录

      await this.getPersonalNotice();
      await this.getPersonal();
    },
  },

  created() {
    let index = this.$route.query.index;
    if (index) {
      this.activeName = index;
    }
  },

  computed: {
    ...mapState("userManagement", ["userInfo"]),
  },

  methods: {
    ...mapActions("userManagement", ["getPersonal"]),

    // 进入详情页面
    jump(item) {
      let url;
      // 新闻
      if (!item.data.platform) {
        
        if (item.data.data.news_id) {
          url = "/index-newsDetail?id=" + item.data.data.news_id;

          // 发现页面内容
        } else if (item.type == 3) {
          if (item.data.data.discover_type == 3) {
            url = "/found-details-videoDetails?id=" + item.id;
          } else {
            url = "/found-details-details?id=" + item.id;
          }
        } else if (item.type == 5) {
          if (item.data.data.discover_type == 3) {
            url =
              "/found-details-videoDetails?id=" +
              item.comment.answer_id +
              "&did=" +
              item.id;
          } else {
            url =
              "/found-details-details?id=" +
              item.comment.answer_id +
              "&did=" +
              item.id;
          }
        } else {
          if (item.data.data.video_id) {
            url =
              "/index-syllabus-videoLectureDetail?video_uid=" +
              item.data.data.video_id;
          } else {
            if (item.data.data.discover_type == 3) {
              url = "/found-details-videoDetails?id=" + item.data.discover_id;
            } else {
              url = "/found-details-details?id=" + item.data.discover_id;
            }
          }
        }
      } else {
        url = "/drugsDetail?id=" + item.data.discover_id;
      }

      this.xin.goUrl(url);
    },

    //获取消息
    async getPersonalNotice() {
      this.loading = true;
      let { activeName } = this;
      let { data, code } = await this.$userApi.getPersonalNotice({
        type: activeName,
      });
      if (code == 200) {
        let newNotice = await this.$userApi.getPersonalNewNotice({
          type: activeName,
        });
        if(newNotice.data){
        data = newNotice.data.concat(data);
        }
        data.forEach((v) => {
          if (v.data && v.data.data && v.data.data.images) {
            v.data.data.images = JSON.parse(v.data.data.images);
          }
        });
        this.msgList = data;
      }
      this.loading = false;
    },

    // tab栏点击
    handleClick() {
      if (this.loading) return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.page {
  background-color: #f2f2f2;
  /deep/ {
    .el-tabs .el-tabs__item.is-active {
      color: $--themeColor;
      transform: scale(1);
      font-weight: 500;
    }
    .el-tab-pane {
      // min-height: 400px;
    }
    .el-tabs .el-tabs__header {
      margin-bottom: 0px;
    }
    .el-tabs__item {
      padding: 0 25px;
    }
    .el-badge__content {
      background: #e60012;
      top: 10px;
      right: 0px;
    }
  }
  .tabs-box {
    background: #fff;
    padding: 15px 25px;
    .notif-box {
      min-height: 400px;
      padding: 0 30px;
      .notif-zi {
        padding: 30px 0;
        width: 100%;
        border-bottom: 1px solid #f2f2f2;
        display: flex;
        > img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 50%;
          cursor: pointer;
        }
        .notif-right {
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          .notif-top {
            // display: flex;
            // justify-content: flex-start;
            width: 100%;
            .name {
              cursor: pointer;
              color: #333;
              font-weight: 700;
            }
            .huif {
              color: #8f8f8f;
              margin-left: 15px;
            }
            .time {
              font-weight: 400;
              text-align: right;
              color: #8f8f8f;
              float: right;
            }
          }
          .notif-bottom {
            .content {
              margin-top: 10px;
              font-weight: 400;
              text-align: left;
              color: #333333;
              line-height: 1.6;
              white-space: pre-wrap;
            }
            .content-child {
              cursor: pointer;
              margin-top: 10px;
              background: #f2f2f2;
              // height: 60px;
              line-height: 60px;
              display: flex;
              .sheng {
                margin-left: 20px;
                height: 60px;
                overflow: hidden;
              }
              /deep/ img {
                width: 60px;
                height: 60px;
                object-fit: cover;
              }
              span {
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
