var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('section',[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',[_vm._v("消息通知")])],1),_c('section',{staticClass:"tabs-box"},[_c('el-tabs',{attrs:{"before-leave":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"全部"}}),_c('el-tab-pane',{attrs:{"label":"系统通知"}}),_c('el-tab-pane',{attrs:{"label":"评论"}}),_c('el-tab-pane',{attrs:{"label":"粉丝"}}),_c('el-tab-pane',{attrs:{"label":"点赞"}})],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"notif-box"},[(!_vm.loading && !_vm.msgList.length)?_c('div',{staticClass:"null-box"},[_c('img',{attrs:{"src":_vm.$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png',"alt":"","srcset":""}}),_c('div',{staticClass:"title"},[_vm._v("暂无数据")])]):_vm._e(),_vm._l((_vm.msgList),function(item){return [(item.type == 2 && item.data.doctor.user_id)?_c('div',{key:item.id,staticClass:"notif-zi"},[_c('img',{attrs:{"src":item.data.doctor.photo
                    ? item.data.doctor.photo
                    : require('@image/default.png'),"alt":""},on:{"click":function($event){return _vm.xin.goUrl('/found-personal?id=' + item.data.doctor.user_id)}}}),_c('div',{staticClass:"notif-right"},[_c('div',{staticClass:"notif-top"},[_c('span',{staticClass:"name",on:{"click":function($event){return _vm.xin.goUrl(
                        '/found-personal?id=' + item.data.doctor.user_id
                      )}}},[_vm._v(_vm._s(item.data.doctor.name))]),_c('span',{staticClass:"huif"},[_vm._v("回复了")]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm._f("timeFormat")(item.create_time,"yyyy-mm-dd-hh:mm")))])]),_c('div',{staticClass:"notif-bottom"},[(item.data.content)?_c('div',{staticClass:"content sheng",domProps:{"innerHTML":_vm._s(item.data.content)}}):_vm._e(),_c('div',{staticClass:"content-child",on:{"click":function($event){return _vm.jump(item)}}},[(
                        item.data.data &&
                        item.data.data.images &&
                        item.data.data.images[0]
                      )?_c('img',{attrs:{"src":item.data.data.images[0],"alt":""}}):_vm._e(),(
                        item.data.data &&
                        !item.data.data.title &&
                        item.data.data.type == 2
                      )?_c('div',{staticClass:"sheng",domProps:{"innerHTML":_vm._s(item.data.data.content)}}):_vm._e(),(
                        item.data.data &&
                        item.data.data.title &&
                        item.data.data.type == 2
                      )?_c('div',{staticClass:"sheng",domProps:{"innerHTML":_vm._s(item.data.data.title)}}):_vm._e(),(
                        item.data.data &&
                        item.data.data.title &&
                        item.data.data.type != 2
                      )?_c('div',{staticClass:"sheng",domProps:{"innerHTML":_vm._s(_vm.xin.formatRichText(item.data.data.title))}}):_vm._e(),(
                        item.data.data &&
                        !item.data.data.title &&
                        item.data.data.content &&
                        item.data.data.type != 2
                      )?_c('div',{staticClass:"sheng",domProps:{"innerHTML":_vm._s(_vm.xin.formatRichText(item.data.data.content))}}):_vm._e()])])])]):(item.type == 3 && item.data)?_c('div',{key:item.id,staticClass:"notif-zi"},[_c('img',{attrs:{"src":item.data.photo
                    ? item.data.photo
                    : require('@image/default.png'),"alt":""},on:{"click":function($event){return _vm.xin.goUrl('/found-personal?id=' + item.data.user_id)}}}),_c('div',{staticClass:"notif-right"},[_c('div',{staticClass:"notif-top"},[_c('span',{staticClass:"name",on:{"click":function($event){return _vm.xin.goUrl('/found-personal?id=' + item.data.user_id)}}},[_vm._v(_vm._s(item.data.name))]),_c('span',{staticClass:"huif"},[_vm._v("关注了你")]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm._f("timeFormat")(item.create_time,"yyyy-mm-dd-hh:mm")))])])])]):(item.type == 4 && item.data)?_c('div',{key:item.id,staticClass:"notif-zi"},[_c('img',{attrs:{"src":item.data.doctor.photo
                    ? item.data.doctor.photo
                    : require('@image/default.png'),"alt":""},on:{"click":function($event){return _vm.xin.goUrl('/found-personal?id=' + item.data.doctor.user_id)}}}),_c('div',{staticClass:"notif-right"},[_c('div',{staticClass:"notif-top"},[_c('span',{staticClass:"name",on:{"click":function($event){return _vm.xin.goUrl(
                        '/found-personal?id=' + item.data.doctor.user_id
                      )}}},[_vm._v(_vm._s(item.data.doctor.name))]),_c('span',{staticClass:"huif"},[_vm._v("点赞了")]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm._f("timeFormat")(item.create_time,"yyyy-mm-dd-hh:mm")))])]),_c('div',{staticClass:"notif-bottom",on:{"click":function($event){return _vm.jump(item)}}},[(item.data.data)?_c('div',{staticClass:"content-child"},[(
                        item.data.data.images && item.data.data.images.length
                      )?_c('img',{attrs:{"src":item.data.data.images[0],"alt":""}}):_vm._e(),(
                        item.data.data.type == 2 &&
                        !item.data.data.title &&
                        item.data.data.content
                      )?_c('div',{staticClass:"sheng",domProps:{"innerHTML":_vm._s(item.data.data.content)}}):_vm._e(),(item.data.data.type == 2 && item.data.data.title)?_c('div',{staticClass:"sheng",domProps:{"innerHTML":_vm._s(item.data.data.title)}}):_c('div',{staticClass:"sheng",domProps:{"innerHTML":_vm._s(item.data.data.content)}})]):_vm._e()])])]):_c('div',{key:item.id,staticClass:"notif-zi",staticStyle:{"display":"block"}},[(item.content == `您有一条新的回答` && item.data)?_c('div',{key:item.id,staticClass:"notif-zi"},[_c('img',{attrs:{"src":item.data.doctor.photo
                      ? item.data.doctor.photo
                      : require('@image/default.png'),"alt":""},on:{"click":function($event){return _vm.xin.goUrl('/found-personal?id=' + item.data.doctor.user_id)}}}),_c('div',{staticClass:"notif-right"},[_c('div',{staticClass:"notif-top"},[_c('span',{staticClass:"name",on:{"click":function($event){return _vm.xin.goUrl(
                          '/found-personal?id=' + item.data.doctor.user_id
                        )}}},[_vm._v(_vm._s(item.data.doctor.name))]),_c('span',{staticClass:"huif"},[_vm._v("回答了")]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm._f("timeFormat")(item.create_time,"yyyy-mm-dd-hh:mm")))])]),_c('div',{staticClass:"notif-bottom"},[(item.data.content)?_c('div',{staticClass:"content sheng",domProps:{"innerHTML":_vm._s(item.data.content)}}):_vm._e(),_c('div',{staticClass:"content-child",on:{"click":function($event){return _vm.jump(item)}}},[(
                          item.data.data &&
                          item.data.data.images &&
                          item.data.data.images[0]
                        )?_c('img',{attrs:{"src":item.data.data.images[0],"alt":""}}):_vm._e(),(item.data.data && item.data.data.type == 2)?_c('div',{staticClass:"sheng",domProps:{"innerHTML":_vm._s(item.data.data.content)}}):_vm._e(),(
                          item.data.data &&
                          item.data.data.title &&
                          item.data.data.type != 2
                        )?_c('div',{staticClass:"sheng",domProps:{"innerHTML":_vm._s(_vm.xin.formatRichText(item.data.data.title))}}):_vm._e(),(
                          item.data.data &&
                          !item.data.data.title &&
                          item.data.data.content &&
                          item.data.data.type != 2
                        )?_c('div',{staticClass:"sheng",domProps:{"innerHTML":_vm._s(_vm.xin.formatRichText(item.data.data.content))}}):_vm._e()])])])]):_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"width":"50px","height":"50px"},attrs:{"src":_vm.$http.baseURL + '/static/images/web/072fc2018e6867c1.png',"alt":""}}),_c('div',{staticClass:"notif-right"},[_c('div',{staticClass:"notif-top"},[_c('span',{staticClass:"name"},[_vm._v("系统通知")]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm._f("timeFormat")(item.create_time,"yyyy-mm-dd-hh:mm")))])]),_c('div',{staticClass:"notif-bottom"},[_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(item.content)}})])])])])]}),(_vm.msgList.length)?_c('div',{staticClass:"no-more"},[_vm._v("没有更多数据了")]):_vm._e()],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }